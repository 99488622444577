<template>
    <div class="exterier-interier-container">
        <b-container>
            <b-row align-h="center">
                <b-col>
                    <img src="../../assets/images/about/interier.png" alt="" class="w-100">
                </b-col>
                <b-col>
                    <img src="../../assets/images/about/exterier.png" alt="" class="w-100">
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "ExterierInterier"
    }
</script>

<style lang="scss" scoped>
    .exterier-interier-container{
        background:#fff;
    }
</style>