<template>
    <div class="list-item-container">
        <div class="service-title">{{service.title}}</div>
        <div v-for="(item, key) in service.items" :key="key" class="list-item-row" :style="service.items.length-1 === key && 'border:0'">
            <b-row align-h="center">
                <b-col cols xs="4" sm="4" md="6" lg="7" xlg="8">
                    <div class="item-title">{{item.title}}</div>
                </b-col>
                <b-col cols xs="4" sm="4" md="3" lg="2" xlg="2">
                    <div v-if="!item.isFree"  class="ourPrice">
                        {{item.ourPrice | currency('€', 0, { spaceBetweenAmountAndSymbol: true})}}
                    </div>
                    <div v-else class="ourPrice">{{item.ourPrice}}</div>
                </b-col>
                <b-col cols xs="4" sm="4" md="3" lg="3" xlg="2">
                    <div class="avgPrice">{{item.avgPrice | currency('€', 0, { spaceBetweenAmountAndSymbol: true})}}</div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ListItem",
        props:['service']
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/custom";
    .list-item-container{
        .service-title{
            margin-top:30px;
            border-bottom:1px solid $black;
            font-family:ProductSans-Black;
            font-size:18px;
            color:$black;
            opacity:0.3;
        }
        .list-item-row{
            padding:20px 0;
            border-bottom:1px solid rgba(21, 29, 52, 0.1);;
            .item-title{
                font-family:ProductSans-Light;
                font-size:18px;
                color:$black;
                opacity:0.6;
                cursor:pointer;
                transition:all 0.3s;
                &:hover{
                    opacity:1;
                }
            }
            .ourPrice{
                font-family:ProductSans-Light;
                font-size:18px;
                color:$primary;
                opacity:0.6;
                cursor:pointer;
                transition:all 0.3s;
                &:hover{
                    opacity:1;
                }
            }
            .avgPrice{
                font-family:ProductSans-Light;
                font-size:18px;
                color:$black;
                opacity:0.6;
                cursor:pointer;
                transition:all 0.3s;
                &:hover{
                    opacity:1;
                }
            }
        }
    }

</style>