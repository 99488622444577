<template>
    <div class="service-list-container">
        <img src="../../assets/images/services/dental-technician-working@2x.png" alt="" class="dw-intro-pic">
        <b-container id="service-list">
            <b-row>
                <service-card v-for="(srv, idx) in services" :service="srv"
                              :key="idx" :idx="idx">
                </service-card>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import ServiceCard from "./ServiceCard";
    import {mapGetters} from "vuex";
    import servicesDataEn from "../../store/data/dental-services-en.json"
    import servicesDataHr from "../../store/data/dental-services-hr.json"
    import servicesDataIt from "../../store/data/dental-services-it.json"
    export default {
        name: "ServiceList",
        components:{
            'service-card': ServiceCard
        },
        methods   : {
            slidePrev() {
                this.$refs.swiper.$swiper.slidePrev();
            },
            slideNext() {
                this.$refs.swiper.$swiper.slideNext();
            },
            getServicesData(){
                let services;
                switch (this.language) {
                    case "en":
                        services = servicesDataEn;
                        break;
                    case "hr":
                        services = servicesDataHr;
                        break;
                    case "it":
                        services = servicesDataIt;
                        break;
                    default:
                        return servicesDataEn
                }
                // services.map(srv => srv.img = require(`../../assets/images/services/${srv.img}`));
                return services;
            }
        },
        computed  : {
            ...mapGetters(['getLanguage']),
            language(){
                return this.getLanguage;
            },
            swiper      : () => {
                return this.$refs.swiper.swiper;
            },
            mobileSwiper: () => {
                return this.$refs.mobileSwiper.swiper;
            },
            tabletSwiper: () => {
                return this.$refs.tabletSwiper.swiper;
            },
            services: {
                get(){
                    return this.getServicesData();
                }

            }
        }
    }
</script>

<style lang="scss" scoped>
@import "../../assets/styles/margins.css";
    .service-list-container{
        background: #fff;
        padding:130px 0;
        .dw-intro-pic {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 0;
            @media only screen and (min-width : 320px) and (max-width: 1400px) {
                opacity:0.3;
            }
        }
    }
</style>
