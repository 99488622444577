<template>
    <div class="certificates-container">
        <b-container>
            <b-row align-h="center">
                <b-col>
                    <div class="d-flex justify-content-around flex-wrap">
                        <enlargeable-image v-for="(img, key) in images" :src="img.thumb" :src_large="img.large" :key="key" />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import EnlargeableImage from "@diracleo/vue-enlargeable-image";
    export default {
        name: "Certificates",
        components:{
            'enlargeable-image':EnlargeableImage
        },
        computed:{
            images(){
                return [
                    {thumb:require('../../assets/images/about/certificate-1.png'), large: require('../../assets/images/about/certificate-1-lg@3x.png')},
                    {thumb:require('../../assets/images/about/certificate-2.png'), large: require('../../assets/images/about/certificate-2-lg@3x.png')},
                    {thumb:require('../../assets/images/about/certificate-3.png'), large: require('../../assets/images/about/certificate-3-lg@3x.png')},
                    {thumb:require('../../assets/images/about/certificate-4.png'), large: require('../../assets/images/about/certificate-4-lg@3x.png')},
                    {thumb:require('../../assets/images/about/certificate-5.png'), large: require('../../assets/images/about/certificate-5-lg@3x.png')},
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .certificates-container{
        background: #fff;
        padding-top:100px;
        padding-bottom:130px;
        img{
            cursor:pointer;
        }
    }
</style>