<template lang="html">
    <div class="header">
        <b-container class="d-flex justify-content-end align-items-center">
            <div class="header-box">
                <b-icon-telephone-fill></b-icon-telephone-fill>
                <span class="phone">+387 36 651 889</span>
            </div>
            <div class="header-box">
                {{getTranslation('first_visit')}}<span> - {{getTranslation('free')}}</span>
            </div>
            <div class="header-box">
                <img src="../assets/images/languages/croatian.png" alt="" @click="switchLanguage('hr')" :class="language !== 'hr' && 'inactive-language'">
                <img src="../assets/images/languages/italian.png" alt="" @click="switchLanguage('it')" :class="language !== 'it' && 'inactive-language'">
                <img src="../assets/images/languages/english.png" alt="" @click="switchLanguage('en')" :class="language !== 'en' && 'inactive-language'">
            </div>
        </b-container>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
export default {
  name:"Header",
    computed:{
        ...mapGetters(['getLanguage']),
        language(){
            return this.getLanguage;
        },
    },
    methods:{
        ...mapActions(['changeLanguage']),
        switchLanguage(lang){
            this.changeLanguage(lang);
        }
    }
}
</script>

<style lang="scss" scoped >
    @import '../assets/styles/custom.scss';
    .header{
        position: relative;
        height:80px;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 2;
        color:$black;
        font-size:16px;
        .header-box{
            height:80px;
            display:flex;
            justify-content: center;
            align-items: center;
            padding:0 30px;
            font-family: ProductSans-Light;
            &:nth-child(2){
                border-left:1px solid rgba(21, 29, 52, 0.1);
                border-right: 1px solid rgba(21, 29, 52, 0.1);
                span{
                    font-family: ProductSans-Medium;
                    margin-left:5px;
                    font-weight: 500;
                }
            }
            img{
                height:25px;
                width:25px;
            }
            img:nth-child(2){
                margin:0 10px;
            }
            .inactive-language{
                opacity:0.5;
            }
        }
        .phone{
            font-family: ProductSans-Medium;
            margin-left: 10px;
        }
    }

</style>
