<template>
    <div class="single-service-container">
        <b-container>
            <b-row align-h="center">
                <b-col cols xs="12" sm="12" md="12" lg="10" xlg="10">
                    <div class="single-service-inner-container">
                        <div class="title">
                            {{service.title}}
                        </div>
                        <div class="subtitle">
                            {{service.subtitle}}
                        </div>
                        <div class="description">
                            {{service.description}}
                        </div>
                        <div class="service-image">
                            <img :src="service.img" alt="">
                        </div>
                        <div class="description">
                            {{service.long_description}}
                        </div>
                        <div class="contact-us-btn" @click="$router.push('/contact')">
                            <b-button variant="primary">{{getTranslation('contact_us')}}</b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import servicesDataEn from "../../store/data/dental-services-en.json";
    import servicesDataHr from "../../store/data/dental-services-hr.json";
    import servicesDataIt from "../../store/data/dental-services-it.json";
    import {mapGetters} from "vuex";
    export default {
        name: "ServiceSingle",
        methods:{
          getServicesData(){
              let servicesData;
              switch (this.language) {
                  case "en":
                      servicesData = servicesDataEn;
                      break;
                  case "hr":
                      servicesData = servicesDataHr;
                      break;
                  case "it":
                      servicesData = servicesDataIt;
                      break;
                  default: servicesData = servicesDataEn;
              }
              return servicesData;
          }
        },
        computed:{
            ...mapGetters(['getLanguage']),
            language(){
                return this.getLanguage;
            },
            servicesData(){
                return this.getServicesData();
            },
            service(){
                let service = {};
                this.servicesData.map(srv => {
                    if(srv.path === this.$route.params.service){
                      service = srv;
                    }
                    return srv;
                });
                return service;
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/custom.scss";
    .single-service-container{
        background:#fff;
        padding-top:150px;
        .single-service-inner-container{
            .title{
                font-family:ProductSans-Medium;
                font-size:40px;
                color:$primary;
            }
            .subtitle{
                margin-top:40px;
                margin-bottom:24px;
                font-family:ProductSans-Bold;
                font-size:24px;
                color:$black;
            }
            .description{
                font-family:ProductSans-Light;
                font-size:20px;
                color:$black;
            }
            .service-image{
                text-align:center;
                margin:40px 0;
                img{
                    width:100%;
                }
            }
            .contact-us-btn{
                margin-top:40px;
                margin-bottom:130px;
                text-align:center;
                button{
                    border-radius:10px;
                    padding: 8px 39px;
                    font-family: ProductSans-Medium;
                    font-size:16px;
                }
            }
        }
    }
</style>
