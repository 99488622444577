<template>
    <div class="dw-list-container">
        <b-container>
            <b-row align-h="center">
                <b-col col xs="12" sm="12" md="10" lg="10" xlg="8">
                    <digital-workflow-card v-for="(card, idx) in digitalWorkflowList"
                                           :card="card" :key="idx" :idx="idx">
                    </digital-workflow-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import DigitalWorkFlowCard from "./DigitalWorkflowCard"
    import DigitalWorkflowListEn from '../../store/data/digital-workflow-list-en.json';
    import DigitalWorkflowListHr from '../../store/data/digital-workflow-list-hr.json';
    import DigitalWorkflowListIt from '../../store/data/digital-workflow-list-it.json';
    import {mapGetters} from "vuex";
    export default {
        name      : "DigitalWorkflowList",
        components: {
            'digital-workflow-card': DigitalWorkFlowCard
        },
        methods:{
          getDigitalWorkflowList(){
              let list;
              switch(this.language){
                  case "en":
                      console.log("EN")
                      list = DigitalWorkflowListEn;
                      break;
                  case "hr":
                      list = DigitalWorkflowListHr;
                      break;
                  case "it":
                      console.log("IT")
                      list = DigitalWorkflowListIt;
                      break;
                  default: return  list = DigitalWorkflowListEn;
              }
          // list.map(item => item.img = require(`../../assets/images/digital-workflow/${item.img}`));
          return list;
          }
        },
        computed:{
            ...mapGetters(['getLanguage']),
            language(){
                return this.getLanguage;
            },
            digitalWorkflowList(){
                return this.getDigitalWorkflowList();
            }
        }
    }
</script>

<style type="scss" scoped>
    .dw-list-container {
        background-color: #fff;
        padding-top:130px;
    }
</style>
