<template lang="html">
  <b-container fluid class="footer-container">
    <b-container>
      <b-row>
        <b-col>
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <div class="title">{{getTranslation('book_an_appointment_now')}}</div>
              <div class="phonecall">
                  <a href="tel:+387 36 651 889">
                      {{getTranslation('call')}}: +387 36 651 889
                  </a>
              </div>
            </div>
            <div class="footer-navigation align-items-end d-flex">
              <b-nav small>
                  <b-nav-item to="/">
                      {{getTranslation('homepage')}}
                  </b-nav-item>
                  <b-nav-item to="/digital-workflow">
                      {{getTranslation('digital_workflow')}}
                  </b-nav-item>
                  <b-nav-item to="/services">
                      {{getTranslation('services')}}
                  </b-nav-item>
                  <b-nav-item to="/price-list">
                      {{getTranslation('price_list')}}
                  </b-nav-item>
                  <b-nav-item to="/about">
                      {{getTranslation('about')}}
                  </b-nav-item>
                  <b-nav-item to="/contact">
                      {{getTranslation('contact')}}
                  </b-nav-item>
                  <b-nav-item to="/contact" class="fb-button">
                      <b-button variant="primary" >Facebook</b-button>
                  </b-nav-item>
              </b-nav>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="underline"></div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex justify-content-between flex-wrap">
            <div class="planinic-rights">
              {{getTranslation('all_right_reserved')}}
            </div>
            <div class="work-time">
              <span>{{getTranslation('working_hours')}}:</span> {{getTranslation('working_days')}} {{getTranslation('working_at_weekend')}}
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="scss" scoped >
@import '../assets/styles/custom.scss';
    .footer-container{
      background:#151d34;
      // height: 280px;
      padding:60px 0px;
      .title{
        font-size: 18px;
        font-family: ProductSans-Medium;
        color: $white;
        opacity:0.6;
      }
      .phonecall{
        font-size: 25px;
        font-family: ProductSans-Black;
        color:$white;
          a{
              color:$white;
          }
      }
      .underline{
        width: 180px;
        height: 1px;
        margin: 39px 313px 40px 0;
        opacity: 0.1;
        background-color: #ffffff;
      }
      .work-time,
      .planinic-rights{
         font-family: ProductSans-Light;
         font-size: 16px;
         color:$white;
         align-self: center;
         opacity: 0.6;
      }
      .work-time span{
        font-family:ProductSans-Medium;
      }
      .footer-navigation{
        font-size:18px;
        opacity:0.6;
        color:$white;
      }
      .nav-item{
        align-self:center;
      }
      .nav-link{
        color:$white;
        opacity:0.6;
        font-family: ProductSans-Medium;
        font-size:16px;
      }
      .nav-link:focus,
      .nav-link:hover{
        color:#fff;
          opacity:1;
      }

      .nav-item-active-class{
        color:#fff;
          opacity:1;
      }
      .fb-button{
        button{
          border-radius:20px;
          padding-left:20px;
          padding-right:20px;
            opacity:1;
        }
        .nav-link{
          opacity:1;
        }
      }

    }
</style>
