<template lang="html">
    <div class="price-list-container">
        <croatian-price-list v-if="language === 'hr'" />
        <list id="desktop-list" v-if="language !== 'hr'" />
        <mobile-list id="mobile-list" v-if="language !== 'hr'"/>
        <appointment-form-2x2 />
    </div>
</template>

<script>
import PriceList from  "../components/PriceList/List";
import MobilePriceList from  "../components/PriceList/MobilePriceList";
import AppointmentForm2x2 from "../components/commmon/AppointmentForm2x2";
import CroatianPriceListHeader from "../components/PriceList/CroatianPriceListHeader";

import {mapGetters} from 'vuex';
export default {
  name:'PriceList',
    components:{
      'list': PriceList,
      'mobile-list': MobilePriceList,
      'appointment-form-2x2': AppointmentForm2x2,
        'croatian-price-list': CroatianPriceListHeader
    },
  data(){
    return {
      title:'Price List'
    }
  },
    computed:{
        ...mapGetters(['getLanguage']),
      language:{
          get(){
              return this.getLanguage;
          }
      }
    }
}
</script>

<style lang="scss" scoped >
    .price-list-container{
        background: #fff;
        #mobile-list{
            display:none;
        }
        @media only screen and (min-width : 320px) and (max-width: 767px) {
            #desktop-list{
                display:none;
            }
            #mobile-list{
                display:block;
            }
        }
    }

</style>
