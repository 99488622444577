<template>
    <div class="dw-card-container">
        <div>
            <b-card :img-src="card.img"
                    img-alt="Image"
                    img-bottom
                    tag="article"
                    class="dw-card-inner-container"
                    no-body
            >
                <b-card-title>
                    <span class="title">
                        <span class="title-first-word">{{getFirstWord(card.title)}}</span>
                        {{getStringExceptOfFirstWord(card.title)}}
                    </span>

                </b-card-title>
                <b-card-text>
                    {{card.description}}
                </b-card-text>
            </b-card>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DigitalWorkflowCard",
        props:['card', 'idx'],
        methods:{
            getFirstWord(str) {
                let spaceIndex = str.indexOf(' ');
                return spaceIndex === -1 ? str : str.substr(0, spaceIndex);
            },
            getStringExceptOfFirstWord(str){
                let spaceIndex = str.indexOf(' ');
                return spaceIndex === -1 ? str : str.substr(spaceIndex, str.lengtj);
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/custom.scss";
    .dw-card-container{
        position: relative;
        z-index: 1;
        .dw-card-inner-container{
            padding: 40px;
            margin-bottom:60px;
            border: 0;
            box-shadow: 10px 20px 60px 0 rgb(0 0 0 / 6%);
        }
        .title{
            font-family:ProductSans-Black;
            font-size: 40px;
            .title-first-word{
                color:$primary;
            }
        }
        .description{
            color:$black;
            font-family:ProductSans-Light;
            font-size: 20px;
        }
    }
</style>