<template lang="html">
    <div class="perfect-solutions-container">
      <img src="../../assets/images/home-intro-pic.png"
              class="home-intro-pic">
        <b-container>
            <b-row>
                <b-col>
                    <div class="content-container">
                         <div class="perfect-solutions-part">
                            {{getTranslation('perfect_solutions')}}
                        </div>
                        <div class="from-true-part">
                            {{getTranslation('from_true')}} <span>{{getTranslation('professionals')}}</span>
                        </div>
                        <div class="description">
                            {{getTranslation('perfect_solutions_desc_1')}} <span>{{getTranslation('perfect_solutions_desc_2')}}</span>, {{getTranslation('perfect_solutions_desc_3')}}<span> {{getTranslation('perfect_solutions_desc_4')}}</span>.
                            {{getTranslation('perfect_solutions_desc_5')}}<span> {{getTranslation('perfect_solutions_desc_6')}}</span>.
                        </div>
                    </div>
                </b-col>
            </b-row>


        </b-container>
    </div>
</template>

<script>
export default {
    name:"PerfectSolutionsDescription",
}
</script>

<style lang="scss" scoped >
@import "../../assets/styles/custom.scss";
.perfect-solutions-container{
    @media only screen and (min-width : 320px) and (max-width: 767px) {
        margin-top:80px;
    }
    font-size: 50px;
    .home-intro-pic{
        position: absolute;
        top:0;
        right:0;
        z-index: 0;
        @media only screen and (min-width : 320px) and (max-width: 1400px) {
            opacity:0.3;
        }
    }
    .content-container{
         position:relative;
         z-index:1;
         height:550px;
         display:flex;
         flex-direction:column;
         justify-content:flex-end;
        .perfect-solutions-part{
            color:$primary;
            font-family: ProductSans-Black;
        }
        .from-true-part{
                color: $black;
                font-family: ProductSans-Light;
                span{
                    color: $primary;
                    font-family: ProductSans-Black;
            }
        }
        .description{
            max-width: 532px;
            font-size: 18px;
            color: $black;
            font-family: ProductSans-Light;
            span{
                font-family: ProductSans-Medium;
                color:$black;
            }
        }
    }
}
</style>
