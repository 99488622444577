<template lang="html">
    <div>
        <intro-section></intro-section>
        <digital-workflow-list></digital-workflow-list>
        <div style="background:#fff">
            <appointment-form-2x2></appointment-form-2x2>
        </div>
    </div>
</template>

<script>
import IntroSection from "../components/DigitalWorkflow/IntroSection";
import DigitalWorkflowList from "../components/DigitalWorkflow/DigitalWorkflowList";
import AppointmentForm2x2 from "../components/commmon/AppointmentForm2x2";
export default {
  name: 'DigitalWorkflow',
    components:{
      'intro-section':IntroSection,
      'digital-workflow-list': DigitalWorkflowList,
      'appointment-form-2x2': AppointmentForm2x2
    },
  data(){
    return {
      title: 'Digital Workflow'
    }
  }
}
</script>

<style lang="scss" scoped >
</style>
