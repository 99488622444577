<template lang="html">
    <div class="appointment-form-container">
        <b-container>
            <b-row align-h="center">
                <b-col col xs="12" sm="12" md="10" lg="10" xlg="10">
                    <b-form @submit="submitForm" class="appointment-form">
                        <b-row>
                            <b-col col xs="12" sm="12" md="10" lg="10" xlg="8">
                                <div><span>{{getTranslation('book_an_appointment')}}</span></div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols xs="12" md="7" lg="8" xlg="8">
                                <b-form-input v-model="email" :class="['input-group', isEmailValid(email)]"
                                              id="inline-form-input-email" type="email"
                                              :placeholder="getTranslation('your_email')">
                                </b-form-input>
                            </b-col>
                            <b-col cols xs="12" md="5" lg="4" xlg="4">
                                <b-form-input v-model="name"
                                              id="inline-form-input-name"
                                              :placeholder="getTranslation('your_name')">
                                </b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols xs="12" md="7" lg="8" xlg="8">
                                <b-form-select v-model="selectedService"
                                               :options="serviceOptions"
                                >
                                </b-form-select>
                            </b-col>
                            <b-col cols xs="12" md="5" lg="4" xlg="4">
                                <b-form-datepicker v-model="selectedDate"
                                                   class="mb-0"
                                                   :placeholder="getTranslation('select_date_range')"
                                                   :locale="this.language"
                                >
                                </b-form-datepicker>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="d-flex justify-content-end" cols xs="12">
                                <vue-recaptcha sitekey="6Le76IsbAAAAAM2EOQWDl_QFREHTbzPRiNn66iKI">
                                    <b-button class="submit-form-btn" variant="primary" @click="submitForm">
                                        {{getTranslation("submit")}}
                                    </b-button>
                                </vue-recaptcha>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    import servicesDataEn from "../../store/data/dental-services-en.json";
    import servicesDataHr from "../../store/data/dental-services-hr.json";
    import servicesDataIt from "../../store/data/dental-services-it.json";
    import {mapGetters} from "vuex";
    import VueRecaptcha from 'vue-recaptcha';
    export default {
        name    : "AppointmentForm2x2",
        components: { 'vue-recaptcha':VueRecaptcha },
        data() {
            return {

                    selectedService  : {},
                    email            : '',
                    name             : '',
                    selectedDate     : ''
            }
        },
        methods : {
            activateLoader(){
                this.loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            },
            disableLoader(){
                this.loading.close();
            },
            async submitForm() {
                if(this.isEmailValid(this.email)){
                    this.activateLoader();
                    let services = this.getServicesData();
                    const emailPayload = {
                        email  : this.email,
                        name   : this.name,
                        service: services.find(srv => srv.value === this.selectedService)?.title,
                        date   : this.selectedDate
                    };

                    if(!emailPayload.service){
                        this.displayNotification({
                            title:this.getTranslation('service_not_selected'),
                            message:this.getTranslation('please_select_service'),
                            color:"#1a63f1"
                        });
                        this.disableLoader();
                    }else{
                        const requestOptions = {
                            method: "POST",
                            body: JSON.stringify(emailPayload)
                        };
                        const response = await fetch("http://dr-planinic.com/book-an-appointment.php", requestOptions);
                        if(!response.ok){
                            this.displayNotification({
                                title:this.getTranslation('something_went_wrong'),
                                message:`${this.getTranslation('try_again')} ${this.getTranslation('thank_you')}`,
                                color:"darkred"
                            });
                            this.disableLoader();
                            console.log(`HTTP error! status: ${response.status}`);
                        }
                        let myJson = await response;
                        if(myJson.ok){
                            const resp = await fetch("http://dr-planinic.com/send-info-to-client.php", requestOptions);
                            if(resp){

                                this.displayNotification({
                                    title:this.getTranslation('thank_you'),
                                    message:this.getTranslation('message_sent_successfully'),
                                    color:"#1a63f1"
                                });
                                this.disableLoader();
                            }
                            if(!resp.ok){
                                this.disableLoader();
                                console.log(`HTTP error! status: ${response.status}`);
                            }
                        }
                    }
                }else{
                    this.displayNotification({
                        title:this.getTranslation('please_enter_your_email'),
                        message:"e.g. example@mail.com",
                        color:"darkred"
                    });
                }

            },
            getServicesData() {
                let services;
                switch (this.language) {
                    case "en":
                        services = servicesDataEn;
                        break;
                    case "hr":
                        services = servicesDataHr;
                        break;
                    case "it":
                        services = servicesDataIt;
                        break;
                    default:
                        return servicesDataEn
                }
                return services;
            }
        },
        computed: {
            ...mapGetters(['getLanguage']),
            language() {
                return this.getLanguage
            },
            serviceOptions() {
                let services = this.getServicesData();
                return [
                    {value: null, text: this.getTranslation('select_service')},
                    ...services
                ]
            },
            dateRangeOptions() {
                return [
                    {value: null, text: this.getTranslation('select_date_range')},
                    {value: 1, text: 'Range 1 1'},
                    {value: 2, text: 'Range 2 2'},
                    {value: 3, text: 'Range 3 3'},
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/custom.scss";

    .appointment-form-container {
        padding: 20px 0;
        position: relative;
        z-index: 2;
        .appointment-form {
            background: $white;
            padding: 20px 40px;
            border-radius: 10px;
            box-shadow: 10px 20px 60px 0 rgba(0, 0, 0, 0.06);
        }
        div {
            span {
                color: $black;
                font-size: 25px;
                font-family: ProductSans-Black;
            }
        }
        form {
            input, select {
                margin: 12px 0;
            }
            .submit-form-btn {
                width: 160px;
                padding: 8px 54px 8px 53px;
                border-radius: 10px;
            }
        }
    }
</style>
