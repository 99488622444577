import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state    : {
        language : 'en',
        languages: ['hr', 'en', 'it']
    },
    getters  : {
        getLanguage(state) {
            return state.language;
        },
        getLanguages(state) {
            return state.languages;
        }
    },
    mutations: {
        SET_LANGUAGE(state, lang) {
            return state.language = lang;
        }
    },
    actions  : {
        changeLanguage({commit}, lang) {
            commit('SET_LANGUAGE', lang);
        }
    }
})
