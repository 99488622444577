<template>
    <div class="mobile-price-list-container">
        <b-container v-for="(item, key) in priceList" :key="key">
            <b-row>
                <b-col>
                    <div class="category-title">{{item.title.toUpperCase()}}</div>
                </b-col>
            </b-row>
            <mobile-list-item v-for="(srv, idx) in item.items" :service="srv" :key="idx" :idx="idx"/>
        </b-container>
    </div>
</template>

<script>
    import MobileListItem from "./MobileListItem";
    import priceListEn from "../../store/data/price-list-en.json";
    import priceListHr from "../../store/data/price-list-hr.json";
    import priceListIt from "../../store/data/price-list-it.json";
    import {mapGetters} from "vuex";
    export default {
        name: "MobilePriceList",
        components:{
            'mobile-list-item': MobileListItem
        },
        methods:{
            getPriceList(){
                let list;
                switch(this.language){
                    case "en":
                        list = priceListEn;
                        break;
                    case "hr":
                        list = priceListHr;
                        break;
                    case "it":
                        list = priceListIt;
                        break;
                    default: list = priceListEn;
                }
                return list;
            }
        },
        computed: {
            ...mapGetters(['getLanguage']),
            language(){
                return this.getLanguage
            },
            priceList(){
                return this.getPriceList();
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/custom.scss";
    .mobile-price-list-container {
        background: $white;
        padding-top: 100px;
        padding-bottom: 20px;
        .category-title{
            margin-top:24px;
            opacity: 0.3;
            font-family: ProductSans-Black;
            font-size: 16px;
            color:$black;
        }
    }
</style>
