<template>
    <div class="contact-intro-section-container">
        <img src="../../assets/images/contact/contact-intro-pic.png" alt="" class="about-intro-pic">
            <b-container>
                <b-row>
                    <b-col>
                        <div class="content-container">
                            <div class="title">
                                {{getTranslation("contact_get_in")}} <span>{{getTranslation("contact_touch")}}</span>
                            </div>
                            <div class="description">
                                {{getTranslation("contact_us_about_everything")}}
                            </div>
                            <b-form>
                                <b-form-input id="inline-form-input-name" type="text" class="mb-8"
                                              :placeholder="getTranslation('your_name')" v-model="name">
                                </b-form-input>
                                <b-form-input id="inline-form-input-phone" type="tel" class="mt-8 mb-8"
                                              :placeholder="getTranslation('your_phone')" v-model="phone">
                                </b-form-input>
                                <b-form-input id="inline-form-input-email" type="email" class="mt-8 mb-8" :class="['input-group', isEmailValid(email)]"

                                              :placeholder="getTranslation('your_email')" v-model="email">
                                </b-form-input>
                                <b-form-textarea id="inline-form-input-email" class="mt-8" v-model="message"
                                              :placeholder="getTranslation('your_message')" rows="6" max-rows="106">
                                </b-form-textarea>
                                <div class="text-right">
                                    <vue-recaptcha sitekey="6Le76IsbAAAAAM2EOQWDl_QFREHTbzPRiNn66iKI">
                                        <b-button class="submit-form-btn" variant="primary" @click="submitForm">{{getTranslation("submit")}}</b-button>
                                    </vue-recaptcha>
                                </div>
                            </b-form>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import VueRecaptcha from 'vue-recaptcha';
    export default {
        name: "IntroSection",
        components: {
            'vue-recaptcha': VueRecaptcha
        },
        data() {
            return {
                phone  : "",
                message: "",
                email: '',
                name: ''
            }
        },
        methods:{
            activateLoader(){
                this.loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            },
            disableLoader(){
                this.loading.close();
            },
            async submitForm() {
              if(this.isEmailValid(this.email)){
                  this.activateLoader();
                  const emailPayload = {
                      email  : this.email,
                      name   : this.name,
                      phone  : this.phone,
                      message   : this.message
                  };
                  const requestOptions = {
                      method: "POST",
                      body: JSON.stringify(emailPayload)
                  };
                  const response = await fetch("http://dr-planinic.com/contact.php", requestOptions)
                  if(!response.ok){
                      this.displayNotification({
                          title:this.getTranslation('something_went_wrong'),
                          message:`${this.getTranslation('try_again')} ${this.getTranslation('thank_you')}`,
                          color:"darkred"
                      });
                      this.disableLoader();
                      throw new Error(`HTTP error! status: ${response.status}`);
                  }
                  let myJson = await response;
                  if(myJson.ok){
                      const resp = await fetch("http://dr-planinic.com/send-info-to-client.php", requestOptions);
                      if(resp){
                          this.disableLoader();
                          this.displayNotification({
                              title:this.getTranslation('thank_you'),
                              message:this.getTranslation('message_sent_successfully'),
                              color:"#1a63f1"
                          });
                      }
                      if(!resp.ok){
                          this.disableLoader();
                          throw new Error(`HTTP error! status: ${response.status}`);
                      }
                  }
              }else{
                  this.displayNotification({
                      title:this.getTranslation('please_enter_your_email'),
                      message:"e.g. example@mail.com",
                      color:"darkred"
                  });
              }
            },
        },
        computed:{
            ...mapGetters(['getLanguage']),
            language(){
                return this.getLanguage
            },
            serviceOptions(){
                let services = this.getServicesData();
                return [
                    {value: null, text: this.getTranslation('select_service')},
                    ...services
                ]
            },
            dateRangeOptions(){
                return [
                    {value: null, text: this.getTranslation('select_date_range')},
                    {value: 1, text: 'Range 1 1'},
                    {value: 2, text: 'Range 2 2'},
                    {value: 3, text: 'Range 3 3'},
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/custom.scss";
    @import "../../assets/styles/margins.css";
    .contact-intro-section-container{
        .about-intro-pic{
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            @media only screen and (min-width : 320px) and (max-width: 1400px) {
                opacity:0.3;
            }
        }
        .content-container {
            padding-top: 230px;
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            max-width: 532px;
            .title{
                font-size:35px;
                font-family:ProductSans-Light;
                color:$black;
                >span{
                    font-family:ProductSans-Black;
                    color:$primary;
                }

            }
            .description{
                margin-bottom:24px;
                font-size:18px;
                font-family:ProductSans-Light;
                color:$black;
            }
            .submit-form-btn{
                width:160px;
                padding: 8px 54px 8px 53px;
                border-radius: 10px;
                margin-top:16px;
            }
            input[type="text"],
            input[type="tel"],
            input[type="email"]{
                height:58px;
                font-family:ProductSans-Regular;
                color:$black;
            }
            input[type="text"]::placeholder,
            input[type="tel"]::placeholder,
            input[type="email"]::placeholder{
                font-family:ProductSans-Regular;
            }
        }
    }
</style>
