<template lang="html">
    <b-container fluid="md" class="service-swiper-container">
        <div>
            <div class="title">{{getTranslation('services')}}</div>
            <div class="subtitle mb-60">
                {{getTranslation('home_services_subtitle')}}
            </div>
        </div>
        <swiper class="swiper desktop-swiper" :options="desktopSwiperOptions" ref="swiper" data-swiper-autoplay="2000">
            <swiper-slide v-for="service in services" :key="service.value">
                <div>
                    <img :src="service.img" :alt="service.placeholder">
                    <div>{{service.title}}</div>
                </div>
            </swiper-slide>
        </swiper>
        <swiper class="swiper mobile-swiper" :options="mobileSwiperOptions" ref="mobileSwiper"
                data-swiper-autoplay="2000">
            <swiper-slide v-for="service in services" :key="service.value">
                <div>
                    <img :src="service.img" :alt="service.placeholder">
                    <div>{{service.title}}</div>
                </div>
            </swiper-slide>
        </swiper>
        <swiper class="swiper tablet-swiper" :options="tabletSwiperOptions" ref="tabletSwiper"
                data-swiper-autoplay="2000">
            <swiper-slide v-for="service in services" :key="service.value">
                <div>
                    <img :src="service.img" :alt="service.placeholder">
                    <div>{{service.title}}</div>
                </div>
            </swiper-slide>
        </swiper>
        <div class="mt-80 d-flex justify-content-center">
            <b-button variant="primary" @click="$router.push('services')">
                {{getTranslation('see_all_services')}}
            </b-button>
        </div>
        <div class="custom-swiper-prev is-previous is-next" @click="slidePrev">
            <b-icon-arrow-left class="prev-arrow"></b-icon-arrow-left>
        </div>
        <div class="custom-swiper-next is-previous is-next" @click="slideNext">
            <b-icon-arrow-right class="next-arrow"></b-icon-arrow-right>
        </div>
    </b-container>
</template>

<script>
    import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
    import 'swiper/swiper.scss';
    import {mapGetters} from "vuex";
    import servicesDataEn from "../../store/data/dental-services-en"
    import servicesDataHr from "../../store/data/dental-services-hr"
    import servicesDataIt from "../../store/data/dental-services-it"

    export default {
        name      : "ServiceSwiper",
        props     : ["service"],
        components: {
            Swiper,
            SwiperSlide
        },
        data() {
            return {
                desktopSwiperOptions: {
                    initialSlide        : 1,
                    slidesPerView       : 3,
                    // autoHeight:true,
                    grabCursor          : true,
                    centeredSlides      : true,
                    centeredSlidesBounds: true,
                    setWrapperSize      : true,
                    roundLengths        : true,
                    spaceBetween        : 20,
                    loop                : true,
                    autoplay            : true,
                    navigation          : {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
                tabletSwiperOptions : {
                    initialSlide        : 0,
                    slidesPerView       : 2,
                    // autoHeight:true,
                    grabCursor          : true,
                    centeredSlides      : true,
                    centeredSlidesBounds: true,
                    setWrapperSize      : true,
                    roundLengths        : true,
                    spaceBetween        : 20,
                    loop                : true,
                    autoplay            : true,
                    navigation          : {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
                mobileSwiperOptions : {
                    initialSlide        : 0,
                    slidesPerView       : 1,
                    // autoHeight:true,
                    grabCursor          : true,
                    centeredSlides      : true,
                    centeredSlidesBounds: true,
                    setWrapperSize      : true,
                    roundLengths        : true,
                    spaceBetween        : 20,
                    loop                : true,
                    autoplay            : true,
                    navigation          : {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                }
            }
        },
        methods   : {
            slidePrev() {
                this.$refs.swiper.$swiper.slidePrev();
            },
            slideNext() {
                this.$refs.swiper.$swiper.slideNext();
            },
            getServicesData(){
                let services;
                switch (this.language) {
                    case "en":
                        services = servicesDataEn;
                        break;
                    case "hr":
                        services = servicesDataHr;
                        break;
                    case "it":
                        services = servicesDataIt;
                        break;
                    default:
                        return servicesDataEn
                }

                return services;
            }
        },
        computed  : {
            ...mapGetters(['getLanguage']),
            language(){
              return this.getLanguage;
            },
            swiper      : () => {
                return this.$refs.swiper.swiper;
            },
            mobileSwiper: () => {
                return this.$refs.mobileSwiper.swiper;
            },
            tabletSwiper: () => {
                return this.$refs.tabletSwiper.swiper;
            },
            services: {
                get(){
                    let services = this.getServicesData();
                    services.map(srv => srv.img = require(`../../assets/images/services/${srv.thumbnail}`));
                    return services;
                }

            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/custom.scss";
    @import "../../assets/styles/margins.css";

    .service-swiper-container {
        position: relative;
        background: $gray;
        padding: 180px 0 150px 0;
        .title {
            font-size: 35px;
            font-family: ProductSans-Black;
            text-align: center;
        }
        .subtitle {
            font-size: 18px;
            font-family: ProductSans-Light;
            text-align: center;
        }
        .mobile-swiper, .tablet-swiper {
            display: none;
        }
        .swiper {
            height: 100%;
            width: 100%;

            .swiper-slide {
                display: flex;
                justify-content: center;
                align-items: center;
                align-self: center;
                width: 280px;
                height: 280px;
                text-align: center;
                font-size: 18px;
                font-family: ProductSans-Medium;
                background-color: $white;
                background-position: center;
                background-size: cover;
                box-shadow: 2px 5px 14px 0 rgba(0, 0, 0, 0.06);
                color: rgba(21, 29, 52, 0.8);
                border-radius: 10px;
                img {
                    margin-bottom: 40px;
                    width: 75px;
                    height: 75px;
                    opacity: 0.8;
                }
            }
            .swiper-slide-active {
                box-shadow: 10px 20px 60px 0 rgba(0, 0, 0, 0.06);
                font-size: 20px;
                width: 330px;
                height: 330px;
                color: $black;
                img {
                    opacity: 1;
                }
            }

            .swiper-pagination {
                background-color: $primary;
                .swiper-pagination-bullet.swiper-pagination-bullet-active {
                    background-color: $black;
                }
            }
        }
        button {
            font-size: 16px;
            font-family: ProductSans-Light;
            width:200px;
        }
        .custom-swiper-prev {
            position: absolute;
            z-index: 1;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $primary;
            top: 50%;
            left: 0;
            box-shadow: 2px 5px 15px 0 rgba(0, 0, 0, 0.06);
            cursor: pointer;
            .prev-arrow {
                color: $primary;
                font-size: 20px;
            }
        }
        .custom-swiper-next {
            position: absolute;
            z-index: 1;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $primary;
            top: 50%;
            right: 0;
            box-shadow: 2px 5px 15px 0 rgba(0, 0, 0, 0.06);
            cursor: pointer;
            .next-arrow {
                color: $primary;
                font-size: 20px;
            }
        }
    }

    @media only screen and (max-width: 1600px) {
        .custom-swiper-next {
            right: 5% !important;
        }
        .custom-swiper-prev {
            left: 5% !important;
        }
    }

</style>
