<template>
    <div class="about-intro-section-container">
        <img src="../../assets/images/about/about-intro-pic.png" alt="" class="about-intro-pic">
        <b-container>
            <b-row>
                <b-col>
                    <div class="content-container">
                        <div class="title">
                            {{getTranslation("about_meet_the")}} <span>{{getTranslation("about_doctor")}}</span>
                        </div>
                        <div class="description">
                            {{getTranslation('about_in_2001')}}<span> {{getTranslation('dr_planinic')}}</span> {{getTranslation('about_graduated_description')}}
                        </div>
                        <div class="dgz">
                            <img src="../../assets/images/about/dgzi.png" alt="">
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "IntroSection"
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/custom.scss";
    .about-intro-section-container{
        background: $white;
        .about-intro-pic{
            position: absolute;
            top: 0;
            right: 0;
            z-index: 0;
            @media only screen and (min-width : 320px) and (max-width: 1400px) {
                opacity:0.3;
            }
        }
        .content-container {
            padding-top:230px;
            position: relative;
            z-index: 1;
            /*height: 550px;*/
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            max-width: 532px;
            .title{
                color:$black;
                font-family: ProductSans-Light;
                font-size:35px;
                >span{
                    color: $primary;
                }
            }
            .description{
                color:$black;
                font-family: ProductSans-Light;
                font-size:18px;
                >span{
                    font-family: ProductSans-Black;
                }
            }
            .dgz{
                height:150px;
                margin:80px 0;
            }
        }
    }
</style>
