import store from '../index';
import hr from "./hr.json";
import en from "./en.json";
import it from "./it.json";

export function getTranslation(key){
    const LANGUAGE = store.getters.getLanguage;
    switch(LANGUAGE){
        case 'hr':
            return hr[key] || key;
        case 'en':
            return en[key] || key;
        case 'it':
            return it[key] || key;
        default: return key;
    }
}