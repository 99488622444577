import Vue from 'vue'
import VueRouter from 'vue-router'

import About from "../views/About";
import Home from "../views/Home";
import Contact from "../views/Contact";
import PriceList from "../views/PriceList";
import Services from "../views/Services";
import DigitalWorkflow from "../views/DigitalWorkflow";
import ServiceSingle from "../components/Services/ServiceSingle"

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/digital-workflow',
    name: 'DigitalWorkflow',
    component: DigitalWorkflow
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
      path: '/services/:service',
      name: 'Service',
      component: ServiceSingle
  },
  {
    path: '/price-list',
    name: 'PriceList',
    component: PriceList
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
import store from "../store/index"
router.beforeEach((to, from, next) => {
    let lang = store.getters.getLanguage;
    if (to.name === 'PriceList' && lang === 'hr') next({ path: '/' })

    next()
});
export default router
