<template>
    <div class="mobile-service-card">
        <b-row>
            <b-col>
                <div class="mobile-service-title">
                    {{service.title}}
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="5">
                <div class="m-our-price-label">Our Price</div>
                <div class="m-our-price"  v-if="!service.isFree">
                    {{service.ourPrice | currency('€', 0, { spaceBetweenAmountAndSymbol: true})}}
                </div>
                <div v-else class="m-our-price">{{service.ourPrice}}</div>
            </b-col>
            <b-col cols="7">
                <div class="m-avg-price-label">
                    Avg EU Price
                </div>
                <div class="m-avg-price">
                    {{service.avgPrice | currency('€', 0, { spaceBetweenAmountAndSymbol: true})}}
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: "MobileListItem",
        props:['service', 'idx']
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/custom.scss";

    .mobile-service-card {
        margin-bottom:16px;
        padding: 16px;
        box-shadow: 10px 20px 60px 0 rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        background: #fff;
        .mobile-service-title {
            font-family: ProductSans-Medium;
            font-size: 16px;
            color: $black;
            margin-bottom: 16px;
        }
        .m-our-price-label {
            color: $blue;
            opacity: 0.6;
            font-family: ProductSans-Light;
            font-size: 16px;
        }
        .m-our-price {
            font-size: 18px;
            font-family: ProductSans-Medium;
            color: $blue;
        }
        .m-avg-price-label {
            color: $black;
            opacity: 0.6;
            font-family: ProductSans-Light;
            font-size: 16px
        }
        .m-avg-price {
            color: $black;
            font-family: ProductSans-Medium;
            font-size: 18px
        }
    }

</style>
