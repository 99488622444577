import Vue from 'vue';
import {getTranslation} from "../store/translations/translateService";

Vue.mixin({
    computed:{
      emailRegex:{
          get(){
              //eslint-disable-next-line
              return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
          }
      }
    },
    methods: {
        getTranslation,
        isEmailValid(email) {
            return (email == "")
                ? ""
                : (this.emailRegex.test(email))
                    ? 'has-success'
                    : 'has-error';
        },
        displayNotification(params){
            const h = this.$createElement;
            this.$notify({
                title: params.title,
                message: h('i', { style: `color: ${params.color}` }, params.message)
            });
        }
    }
});
