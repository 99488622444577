<template>
    <b-col cols xs="12" sm="6" md="6" lg="4" xlg="4" @click="goToSingleService(service)">
        <div class="card-container">
            <img :src="require(`../../assets/images/services/${service.thumbnail}`)" alt="" class="service-svg">
            <div class="title">{{service.title}}</div>
        </div>
    </b-col>
</template>

<script>
    export default {
        name: "ServiceCard",
        props:['service', 'idx'],
        methods:{
            goToSingleService(service){
                this.$router.push({path:`services/${service.path}`})
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/custom.scss";
    .card-container{
        height:300px;
        margin:10px;
        display:flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
        background: $white;
        box-shadow: 2px 5px 15px 0 rgba(0, 0, 0, 0.06);
        .service-svg{
        }
        .title{
            margin-top:40px;
        }
    }
</style>