<template lang="html">
    <div class="dw-container">
        <img src="../../assets/images/digital-workflow/dw-intro-pic.png"
             class="dw-intro-pic">
            <b-container>
                <b-row>
                    <b-col>
                        <div class="content-container">
                            <div>
                                <div class="title">{{getTranslation('digital_workflow')}}</div>
                                <div>
                                    <div class="list-item-container">
                                        <div>
                                            <div>1</div>
                                        </div>
                                        <div>{{getTranslation('3d_mouth_exam')}}</div>
                                    </div>
                                    <div class="list-item-container">
                                        <div>
                                            <div>2</div>
                                        </div>
                                        <div>{{getTranslation('treatment_planning')}}</div>
                                    </div>
                                    <div class="list-item-container">
                                        <div>
                                            <div>3</div>
                                        </div>
                                        <div>{{getTranslation('optical_impression')}}</div>
                                    </div>
                                    <div class="list-item-container">
                                        <div>
                                            <div>4</div>
                                        </div>
                                        <div>{{getTranslation('crown_manufacturing')}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
    </div>
</template>

<script>
    export default {
        name: 'DigitalWorkflow',
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/custom.scss";
    .dw-container {
        background: #fff;
        position:relative;
        z-index:1;

        .dw-intro-pic {
            position: absolute;
            top: -170px;
            right: 0;
            z-index: 0;
            @media only screen and (min-width : 320px) and (max-width: 1400px) {
                opacity:0.3;
            }
        }
        .content-container{
            position:relative;
            z-index:1;
            height:550px;
            display:flex;
            flex-direction:column;
            justify-content:flex-end;
            .title {
                font-size: 35px;
                font-family: ProductSans-Black;
                margin-bottom: 20px;
            }
            .list-item-container {
                display: flex;
                align-items: center;
                font-size: 18px;
                font-family: ProductSans-Light;
                margin-bottom: 10px;
                div:nth-child(1) {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: $primary;
                    color: #fff;
                }
                div:nth-child(2) {
                    margin-left: 15px;
                }
            }
        }

    }
</style>
