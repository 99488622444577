<template lang="html">
    <div class="testimonial-container">
      <div class="d-flex align-items-center mb-20">
        <div>
          <img :src="testimonial.img" alt="" class="avatar">
        </div>
        <div class="name">
          {{testimonial.name}}
        </div>
      </div>
      <div class="testimonial-text">
        {{testimonial.text}}
      </div>
    </div>
</template>

<script>
export default {
  name: 'Testimonial',
  props:['testimonial', 'idx'],
}
</script>

<style lang="scss" scoped >
@import "../../assets/styles/custom.scss";
@import "../../assets/styles/margins.css";
.testimonial-container{
  min-height:220px;
  color:$black;
  box-shadow: 10px 20px 60px 0 rgba(0, 0, 0, 0.06);
  padding: 30px;
  cursor:pointer;
  opacity:0.6;
  transition: opacity 0.3s;
  &:hover{
    opacity:1;
    box-shadow: 10px 20px 60px 0 rgba(0, 0, 0, 0.1);
  }
  .avatar{
      width:50px;
      height:50px;
      border-radius:50%;
      // object-fit: cover;
  }
  .name{
    font-size:20px;
    font-family: ProductSans-Black;
    margin-left:20px;
  }
  .testimonial-text{
    font-size:18px;
    font-family: ProductSans-Light;
  }
}

</style>
