import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import {BootstrapVueIcons} from "bootstrap-vue";
import App from './App.vue'
import store from './store'
import router from './router'
import Vue2Filters from 'vue2-filters'

import EnlargeableImage from '@diracleo/vue-enlargeable-image';
import '@/mixins/generalMixin';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(EnlargeableImage);
Vue.use(Vue2Filters);
Vue.use(BootstrapVueIcons);
Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app');
