<template>
    <div class="price-list-container">
        <img src="../../assets/images/price-list/price-list-intro-pic.png" alt="" class="price-list-intro-pic">
        <b-container>
                <b-row align-h="center">
                    <b-col cols xs="12" sm="12" md="12" lg="10" xlg="10">
                        <div class="price-list-inner-container">
                            <div class="price-list-header">
                                <b-row align-h="center">
                                    <b-col cols xs="4" sm="4" md="6" lg="7" xlg="8" class="">
                                        {{getTranslation("procedure")}}
                                    </b-col>
                                    <b-col cols xs="4" sm="4" md="3" lg="2" xlg="2" class="">
                                        {{getTranslation("our_price")}}
                                    </b-col>
                                    <b-col cols xs="4" sm="4" md="3" lg="3" xlg="2" class="">
                                        {{getTranslation("avg_eu_price")}}
                                    </b-col>
                                </b-row>
                            </div>
                            <list-item v-for="(item, key) in priceList" :key="key" :service="item"></list-item>
                        </div>
                    </b-col>
                </b-row>
        </b-container>
    </div>
</template>

<script>
    import ListItem from "./ListItem";
    import priceListEn from "../../store/data/price-list-en.json";
    import priceListHr from "../../store/data/price-list-hr.json";
    import priceListIt from "../../store/data/price-list-it.json";
    import {mapGetters} from "vuex";
    export default {
        name: "List",
        components:{
            'list-item':ListItem
        },
        methods:{
            getPriceList(){
                let list;
                switch(this.language){
                    case "en":
                        list = priceListEn;
                        break;
                    case "hr":
                        list = priceListHr;
                        break;
                    case "it":
                        list = priceListIt;
                        break;
                    default: list = priceListEn;
                }
            return list;
            }
        },
        computed: {
            ...mapGetters(['getLanguage']),
            language(){
                return this.getLanguage
            },
            priceList(){
                return this.getPriceList();
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/custom";
    .price-list-container{
        background:$white;
        padding-top:200px;
        padding-bottom:20px;
        .price-list-intro-pic{
            position: absolute;
            top: 0;
            right: 0;
            z-index: 0;
            @media only screen and (min-width : 320px) and (max-width: 1400px) {
                opacity:0.3;
            }
        }
        .price-list-inner-container{
            position: relative;
            z-index: auto;
            padding:40px;
            border-radius:10px;
            background: #fff;
            box-shadow: 10px 20px 60px 0 rgba(0, 0, 0, 0.06);
            .price-list-header{
                color:$black;
                font-family: ProductSans-Black;
                font-size:25px;
                :nth-child(2){
                    color:$primary;
                }
            }
        }
    }
</style>
