<template lang="html">
    <div class="contact-container">
        <intro-section></intro-section>
        <contact-map></contact-map>
    </div>
</template>

<script>
  import IntroSection from "../components/Contact/IntroSection";
  import ContactMap from "../components/Contact/ContactMap";


  export default {
  name:"Contact",
  components:{
    'intro-section':IntroSection,
    'contact-map':ContactMap
  },
  data(){
    return {
      title:"Contact"
    }
  }
}
</script>

<style lang="scss" scoped >
    .contact-container{}
</style>
