<template>
    <div class="mobile-footer-container">
        <div class="title">{{getTranslation('book_an_appointment_now')}}!</div>
        <div class="phonecall">
            <a href="tel:+387 36 651 889">
                {{getTranslation('call')}}: +387 36 651 889
            </a>
        </div>
        <div class="mobile-footer-navigation">
            <b-nav vertical align="start">
                <b-nav-item to="/">
                    {{getTranslation('homepage')}}
                </b-nav-item>
                <b-nav-item to="/digital-workflow">
                    {{getTranslation('digital_workflow')}}
                </b-nav-item>
                <b-nav-item to="/services">
                    {{getTranslation('services')}}
                </b-nav-item>
                <b-nav-item to="/price-list">
                    {{getTranslation('price_list')}}
                </b-nav-item>
                <b-nav-item to="/about">
                    {{getTranslation('about')}}
                </b-nav-item>
                <b-nav-item to="/contact">
                    {{getTranslation('contact')}}
                </b-nav-item>
                <!--<b-nav-item to="/contact" class="fb-button">-->
                    <!--<b-button variant="primary" >Facebook</b-button>-->
                <!--</b-nav-item>-->
            </b-nav>
        </div>
        <b-row>
            <b-col>
                <div class="underline"></div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div class="work-time">
                    <div>
                        <span>{{getTranslation('working_hours')}}:</span>
                    </div>
                    <div>
                        {{getTranslation('working_days')}}
                    </div>
                    <div>
                        {{getTranslation('working_at_weekend')}}
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div class="mobile-planinic-rights">
                    {{getTranslation('all_right_reserved')}}
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: "MobileFooter"
    }
</script>

<style lang="scss" scoped>
    @import '../assets/styles/custom.scss';
    .mobile-footer-container{
        background: $black;
        padding: 48px 24px;
    }
    .title{
        font-size:18px;
        font-family:ProductSans-Medium;
        color:rgba(255, 255, 255, 0.6);
    }
    .phonecall{
        font-size: 25px;
        font-family: ProductSans-Black;
        color:$white;
        a{
            color:#fff;
        }
    }
    .mobile-footer-navigation{
        margin-top:36px;
        opacity:0.6;
        color:$white;
    }
    .nav-link{
        color:$white;
        opacity:0.6;
        font-family: ProductSans-Medium;
        font-size:16px;
        padding-left:0;
    }
    .nav-link:focus,
    .nav-link:hover{
        color:#fff;
        opacity:1;
    }

    .nav-item-active-class{
        color:#fff;
        opacity:1;
    }
    .underline{
        width: 180px;
        height: 1px;
        margin:48px 0;
        opacity: 0.1;
        background-color: $white;
    }
    .work-time{
        font-family: ProductSans-Light;
        font-size: 16px;
        color:$white;
        align-self: center;
        opacity: 0.6;
    }
    .work-time span{
        font-family:ProductSans-Medium;
    }
    .mobile-planinic-rights{
         margin:48px 0 24px 0;
         font-family: ProductSans-Light;
         font-size: 12px;
         color:$white;
         align-self: center;
         opacity: 0.6;
     }
</style>
