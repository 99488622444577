<template>
    <div>
    <b-navbar toggleable="lg" type="light" variant="light" fixed="top">
        <b-navbar-brand href="#">
            <img src="../assets/images/navigation-brand.svg" alt="">
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
                <b-nav-item to="/"  active-class="nav-item-active-class">
                    {{getTranslation('homepage')}}
                    <div class="underline"></div>
                </b-nav-item>
                <b-nav-item to="/digital-workflow">
                    {{getTranslation('digital_workflow')}}
                    <div class="underline"></div>
                </b-nav-item>
                <b-nav-item to="/services">
                    {{getTranslation('services')}}
                    <div class="underline"></div>
                </b-nav-item>
                <b-nav-item to="/price-list">
                    {{getTranslation('price_list')}}
                    <div class="underline"></div>
                </b-nav-item>
                <b-nav-item to="/about">
                    {{getTranslation('about')}}
                    <div class="underline"></div>
                </b-nav-item>
                <b-nav-item to="/contact">
                    {{getTranslation('contact')}}
                    <div class="underline"></div>
                </b-nav-item>
            </b-navbar-nav>

        </b-collapse>
    </b-navbar>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    export default {
        name: "MobileNavigation",
        computed:{
            ...mapGetters(['getLanguage']),
            language:{
                get(){
                    return this.getLanguage;
                }
            }
        },
    }
</script>

<style scoped>

</style>
