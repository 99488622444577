import { render, staticRenderFns } from "./MobileFooter.vue?vue&type=template&id=4ed7d340&scoped=true&"
import script from "./MobileFooter.vue?vue&type=script&lang=js&"
export * from "./MobileFooter.vue?vue&type=script&lang=js&"
import style0 from "./MobileFooter.vue?vue&type=style&index=0&id=4ed7d340&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed7d340",
  null
  
)

export default component.exports