<template lang="html">
    <div>
        <service-list></service-list>
    </div>
</template>

<script>
    import ServiceList from "../components/Services/ServiceList"
    export default {
        name      : 'Services',
        components: {
            'service-list': ServiceList
        },
        data() {
            return {
                title: 'Services'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
