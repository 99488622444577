<template lang="html">
<div class="testimonials-container">
  <b-container>
    <b-row>
      <b-col>
        <div class="testimonials-title">
          Dental Center Reviews
        </div>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col xl="4" lg="4" md="6" sm="12" xs="12" v-for="(tst, idx) in testimonials" :key="idx">
        <testimonial-box :idx="idx" :testimonial="tst" />
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import TestimonialBox from "./TestimonialBox";
import {mapGetters} from "vuex";
import testimonialsEn from "../../store/data/testimonials-en.json";
import testimonialsHr from "../../store/data/testimonials-hr.json";
import testimonialsIt from "../../store/data/testimonials-it.json";
export default {
    name: "Testimonials",
    components:{
      'testimonial-box': TestimonialBox
    },
    computed:{
        ...mapGetters(['getLanguage']),
        language(){
            return this.getLanguage;
        },
        testimonials(){
            return this.getTestimonials();
        }
    },
    methods:{
        getTestimonials(){
            let testimonials;
            switch (this.language) {
                case 'en':
                    testimonials = testimonialsEn;
                    break;
                case 'hr':
                    testimonials = testimonialsHr;
                  break;
                case 'it':
                    testimonials = testimonialsIt;
                  break;
                default: return testimonialsEn;
            }
            return testimonials;
        }
    }
}
</script>

<style lang="scss" scoped >
@import "../../assets/styles/custom.scss";
.testimonials-container{
  background-color:$white;
  padding-top:150px;
  padding-bottom:150px;
  .testimonials-title{
      font-size:35px;
      font-family: ProductSans-Black;
      color:$black;
      text-align: center;
      margin-bottom:60px;
    }
}
</style>
