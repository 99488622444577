<template lang="html">
  <div>
    <perfect-solution-description />
    <appointment-form/>
    <service-card-preview />
    <digital-workflow/>
    <service-swiper />
    <!--<our-doctors />-->
    <accomodation />
    <testimonials />
  </div>
</template>

<script>
import PerfectSolutionsDescription from "../components/Home/PerfectSolutionsDescription";
import AppointmentForm from "../components/commmon/AppointmentForm2x2";
import ServiceCardPreview from "../components/Home/ServiceCardPreview";
import DigitalWorkflow from "../components/Home/DigitalWorkflow";
import ServiceSwiper from "../components/Home/ServiceSwiper";
// import OurDoctors from "../components/Home/OurDoctors";
import Accomodation from "../components/Home/Accommodation";
import Testimonials from "../components/Home/Testimonials";

export default {
    name: 'Home',
    components:{
        'perfect-solution-description': PerfectSolutionsDescription,
        'appointment-form': AppointmentForm,
        'service-card-preview':ServiceCardPreview,
        'digital-workflow': DigitalWorkflow,
        'service-swiper':ServiceSwiper,
        // 'our-doctors': OurDoctors,
        'accomodation': Accomodation,
        'testimonials': Testimonials,
    },
    data(){
        return {
            title: 'Home',
            serviceCards:[
                {
                    title: "All range of Services",
                    description:"We offer you a wide range of dental services, using the latest tools, equipment and materials of state-of-the-art technology.",
                    link: 'See list of services'
                },
                {
                    title: "Emergency Cases",
                    description:"Need an emergency treatment? Call us and we will take care of you as soon as possibble.",
                    link: 'Call Us: +387 36 651 889'
                },
            ],

        }
    }
}
</script>

<style lang="scss" scoped >
@import "../assets/styles/custom.scss";
</style>
