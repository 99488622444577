<template>
    <div class="contact-map-container">
        <img src="../../assets/images/contact/contact-map.png" alt="" class="w-100">
        <div class="pin-location">
            <img src="../../assets/images/contact/map-location-pin.png" alt="">
        </div>
        <!--<div class="contact-info-box">-->
            <!--<div class="title">Information</div>-->
            <!--<div class="phone mt-40 mb-20">-->
                <!--<span>Phone:</span> +387 36 651 889-->
            <!--</div>-->
            <!--<div class="mobile mb-20">-->
                <!--<span>Mobile:</span> +387 63 447 840-->
            <!--</div>-->
            <!--<div class="email mb-20">-->
                <!--<span>Email:</span>davor@dr-planinic.com-->
            <!--</div>-->
            <!--<div class="address">-->
                <!--<div><span>Address:</span></div>-->
                <!--<div>Bijakovici b.b.</div>-->
                <!--<div>88266 Međugorje</div>-->
                <!--<div>Bosna i Hercegovina</div>-->
            <!--</div>-->
        <!--</div>-->
    </div>
</template>

<script>
    export default {
        name: "ContactMap"
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/custom.scss";
    @import "../../assets/styles/margins.css";
    .contact-map-container{
        position:relative;
        .pin-location{
            position:absolute;
            bottom:273px;
            right:198px;
            img{
                /*position*/
            }
        }
        .contact-info-box{
            position:absolute;
            bottom:0;
            right:0;
            max-width:380px;
            min-width:280px;
            padding:50px 40px;
            background:#fff;
            box-shadow: 10px 20px 60px 0 rgba(0, 0, 0, 0.06);
            border-radius:10px;
            font-size:18px;
            color:$black;
            font-family:ProductSans-Light;
            span{
                color:$primary;
                font-family:ProductSans-Medium;
            }
            .title{
                font-family:ProductSans-Black;
                color: $primary;
            }
        }
    }
</style>