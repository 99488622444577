<template lang="html">
        <div :style="{'background-color': $route.path === '/contact' ? '#f8f7f8' : '#fff'}" >
            <b-container>
                <b-navbar toggleable="lg" type="light" variant="" class="navigation-container">
                    <b-navbar-brand >
                        <router-link to="/">
                            <img src="../assets/images/navigation-brand.svg" alt="">
                        </router-link>
                    </b-navbar-brand>

                    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                    <b-collapse id="nav-collapse" is-nav>
                        <b-navbar-nav class="ml-auto">
                            <b-nav-item to="/"  active-class="nav-item-active-class">
                                {{getTranslation('homepage')}}
                                <div class="underline"></div>
                            </b-nav-item>
                            <b-nav-item to="/digital-workflow">
                                {{getTranslation('digital_workflow')}}
                                <div class="underline"></div>
                            </b-nav-item>
                            <b-nav-item to="/services">
                                {{getTranslation('services')}}
                                <div class="underline"></div>
                            </b-nav-item>
                            <b-nav-item to="/price-list">
                                {{getTranslation('price_list')}}
                                <div class="underline"></div>
                            </b-nav-item>
                            <b-nav-item to="/about">
                                {{getTranslation('about')}}
                                <div class="underline"></div>
                            </b-nav-item>
                            <b-nav-item to="/contact">
                                {{getTranslation('contact')}}
                                <div class="underline"></div>
                            </b-nav-item>
                            <!--<b-nav-item to="/contact">-->
                            <!--<scill-popover-preview access-token="my-access-token"-->
                            <!--app-id="593776232582742019"-->
                            <!--challenge-id="627581542241501194"-->
                            <!--api-key="3:8OZ=I6M~$((bzTB&E&OJRXcLE48S!:'x),F.8I*,"-->
                            <!--user-id="1234"-->
                            <!--username="franjoeric"-->
                            <!--battle-pass-id="627086321190174723">-->
                            <!--</scill-popover-preview>-->
                            <!--</b-nav-item>-->
                        </b-navbar-nav>
                    </b-collapse>
                </b-navbar>
            </b-container>
        </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
    name: 'Navigation',
    data(){
        return {
            activeItem:""
        }
    },
    computed:{
        ...mapGetters(['getLanguage']),
        language:{
            get(){
                return this.getLanguage;
            }
        }
    },
    created(){
        if(this.$route.name === 'Home'){
            this.activeItem = 'Home';
        }else{
            this.activeItem = "";
        }
    },
}
</script>

<style lang="scss" scoped >
@import "../assets/styles/custom.scss";
$animation_opacity: 0;
.navigation-container{
    height:90px;
    font-size: 16px;
    font-family: ProductSans-Medium;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    border-radius: 10px;
    background-color: #fff;
    position: relative;
    z-index: 2;
}
.nav-item-active-class,
.navbar-light .navbar-nav .nav-link{
    color: $black;
    padding:0 15px;
    .underline{
        width:20px;
        height:2px;
        background-color: $blue;
        margin-top:5px;
        margin-left:50%;
        transform: translateX(-50%);
        opacity: 0;
    }
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover{
    color:$primary;
    font-family: ProductSans-Medium;
    .underline{
        animation: fade-in 0.5s;
        opacity: 1;

    }
    animation: fade-in-color 0.5s;
}

.nav-item-active-class{
    color:$primary;
    font-family: ProductSans-Medium;
    .underline{
        opacity:1;
    }
}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes fade-out-color {
    100% { color: $primary; }
    0% { color: $black; }
}
@keyframes fade-in-color {
  0% { color: $black; }
  100% { color: $primary; }
}
</style>
