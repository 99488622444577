<template lang="html">
  <div class="accomodation-container">
        <div class="accomodation-inner-container">
          <div>
                <div class="description-container">
                    <div class="title">
                        {{getTranslation('home_accommodation')}}
                </div>
                <div class="description">
                    {{getTranslation('home_accommodation_description')}}
                </div>
              </div>
          </div>
          <div class="text-right">
              <img src="../../assets/images/accommodation.png"/>
          </div>
        </div>
  </div>
</template>

<script>
export default {
  name: 'Accommodation'
}
</script>

<style lang="scss" scoped >
@import "../../assets/styles/custom.scss";
    .accomodation-container{
      display:flex;
      margin-top:150px;
      background-color: $white;
      background: url('../../assets/images/accomodation-curved-bg.png');
      background-size: cover;
      .accomodation-inner-container{
        z-index: 1;
        position:relative;
        display:flex;
        flex:1;
        flex-wrap:wrap;
        align-items:center;
          justify-content: center;
        > div {
          flex:0.5;
          min-width:350px;
        }
      }
      .curved-bg{
        position:absolute;
        z-index:0;
        width:100%;
        height:100%;
      }
      .description-container{
        padding-left:15%;
        padding-right:5%;
        align-self: center;
      }
      .title{
        color:$black;
        font-size:35px;
        font-family:ProductSans-Black;
      }
      .description{
        font-size:18px;
        font-family:ProductSans-Light;
      }
      img{
        background-color:$gray;
        width:100%;
      }
    }
</style>
