<template lang="html">
    <div class="digital-workflow-container">
        <img src="../../assets/images/digital-workflow-curved-bg.png" alt="" class="curved-bg">
        <div class="digital-workflow-inner-container">
            <div class="text-left">
                <img src="../../assets/images/digital-workflow-home.png"/>
            </div>
            <div>
                <div class="title">{{getTranslation('digital_workflow')}}</div>
                <div>
                    <div class="list-item-container">
                        <div>
                            <div>1</div>
                        </div>
                        <div>{{getTranslation('3d_mouth_exam')}}</div>
                    </div>
                    <div class="list-item-container">
                        <div>
                            <div>2</div>
                        </div>
                        <div>{{getTranslation('treatment_planning')}}</div>
                    </div>
                    <div class="list-item-container">
                        <div>
                            <div>3</div>
                        </div>
                        <div>{{getTranslation('optical_impression')}}</div>
                    </div>
                    <div class="list-item-container">
                        <div>
                            <div>4</div>
                        </div>
                        <div>{{getTranslation('crown_manufacturing')}}</div>
                    </div>
                </div>
                <div>
                    <b-button variant="primary" class="mt-40" @click="$router.push('digital-workflow')">
                        {{getTranslation('see')}}
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name   : 'DigitalWorkflow'
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/custom.scss";

    .digital-workflow-container {
        display: flex;
        .digital-workflow-inner-container {
            margin-top: 250px;
            padding-bottom: 150px;
            z-index: 1;
            position: relative;
            background: $white;
            //background: $white;
            display: flex;
            flex: 1;
            align-items: center;
            flex-wrap: wrap;
            > div {
                flex: 0.5;
                min-width: 350px
            }
        }
        .curved-bg {
            position: absolute;
            z-index: 0;
            width: 100%;
            height: 100%;
            background: $gray;
        }
        .title {
            color: $black;
            font-size: 35px;
            font-family: ProductSans-Black;
        }
        .description {
            font-size: 18px;
            font-family: ProductSans-Light;
        }
        img {
            width: 90%;
            // background-color:$gray;
        }
        .title {
            font-size: 35px;
            font-family: ProductSans-Black;
            margin-bottom: 20px;
        }
        .list-item-container {
            display: flex;
            align-items: center;
            font-size: 18px;
            font-family: ProductSans-Light;
            margin-bottom: 10px;
            div:nth-child(1) {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $primary;
                color: #fff;
            }
            div:nth-child(2) {
                margin-left: 15px;
            }
        }
        button {
            font-family: ProductSans-Medium;
            font-size: 16px;
            width:200px;
        }
    }
</style>
