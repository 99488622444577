<template>
    <div id="app">
        <Header id="header"/>
        <Navigation id="desktop-navigation"/>
        <MobileNavigation id="mobile-tablet-navigation"/>
        <router-view id="route-view" />
        <Footer id="footer"/>
        <MobileFooter id="mobile-footer"/>
    </div>
</template>

<script>
import Navigation from "./components/Navigation";
import MobileNavigation from "./components/MobileNavigation";
import Header from "./components/Header";
import Footer from "./components/Footer";
import MobileFooter from "./components/MobileFooter";
import {
  mapActions,
  mapGetters
} from "vuex";
export default {
  name: 'App',
  components: {
    Navigation,
    Header,
    Footer,
    MobileNavigation,
    MobileFooter
  },
  data() {
    return {
      title: "App"
    }
  },
  methods: {
    ...mapActions([])
  },
  computed: {
    ...mapGetters([])
  }
}
</script>

<style lang="scss">
    @import './assets/styles/custom.scss';
    @import '../node_modules/bootstrap/scss/bootstrap.scss';
    @font-face {
      font-family: "ProductSans-Light";
      src :url("./assets/fonts/ProductSans-Light.ttf") format("truetype");
      font-weight: normal;
      font-style: normal;
   }
    @font-face {
      font-family: "ProductSans-Regular";
      src: url("./assets/fonts/ProductSans-Regular.ttf") format("truetype");
      font-weight: normal;
      font-style: normal;
   }
    @font-face {
      font-family: "ProductSans-Medium";
      src: url("./assets/fonts/ProductSans-Medium.ttf") format("truetype");
      font-weight: normal;
      font-style: normal;
   }
    @font-face {
      font-family: "ProductSans-Black";
      src: url("./assets/fonts/ProductSans-Black.ttf") format("truetype");
      font-weight: normal;
      font-style: normal;
   }
    @font-face {
      font-family: "ProductSans-Bold";
      src: url("./assets/fonts/ProductSans-Bold.ttf") format("truetype");
      font-weight: normal;
      font-style: normal;
   }
    #app {
        color:$black;
        #desktop-navigation{
            background: $white
        }
        #route-view{
            background-color:$gray;
        }
    }
    #mobile-footer{
        display:none;
    }

    .has-error{
        border-color:darkred;
        outline:darkred;
        box-shadow:0 0 0 0.2rem rgb(139 0 0 /25%);
        &:focus{
            border-color:darkred;
            outline:darkred;
            box-shadow:0 0 0 0.2rem rgb(139 0 0 /25%)
        }
    }
    .has-success{
        border-color:darkgreen;
        outline:darkgreen;
        box-shadow:0 0 0 0.2rem rgb(0 100 0 /25%);
        &:focus{
            border-color:darkgreen;
            outline:darkgreen;
            box-shadow:0 0 0 0.2rem rgb(0 100 0 /25%)
        }
    }


    /* WebKit, Blink, Edge */
    .form-control::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
        font-family: ProductSans-Regular;
       }
    /* Mozilla Firefox 4 to 18 */
    .form-control:-moz-placeholder {
        color: rgba(0, 0, 0, 0.6);
        font-family: ProductSans-Regular;
       }
    /* Mozilla Firefox 19+ */
    .form-control::-moz-placeholder {
        color: rgba(0, 0, 0, 0.6);
        font-family: ProductSans-Regular;
       }
    /* Internet Explorer 10-11 */
    .form-control:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
        font-family: ProductSans-Regular;
       }
    /* Microsoft Edge */
    .form-control::-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
        font-family: ProductSans-Regular;
       }

        /* Custom, iPhone Retina */
        @media only screen and (min-width : 320px) and (max-width: 767px) {
            #header{
                display:none;
            }
            #desktop-navigation{
                display:none;
            }
            #mobile-tablet-navigation{
                display:block;
                position:relative;
                z-index:2;
            }
            #footer{
                display:none;
            }
            #mobile-footer{
                display:block;
            }
            .appointment-form-container{
                #submit-form-btn{
                    width:100%;
                }
            }
            .service-card-container{
                min-width: unset !important;
                .service-card-inner-container{
                    margin:0 0 15px 0 !important;
                    flex:1 !important;
                }

            }
            .digital-workflow-container {
                .curved-bg{
                    /*height:unset !important;*/
                }
                .digital-workflow-inner-container{
                    /*margin:150px 0 !important;*/
                    padding-bottom: 0 !important;
                    justify-content:center;
                    button{
                        width:100%;
                    }
                }
            }

            .service-swiper-container{
                /*padding:0 !important;*/
                .mobile-swiper{
                    display:block !important;
                }
                .desktop-swiper{
                    display:none !important;
                }
                .custom-swiper-prev{
                    /*display:none !important;*/
                }
                .custom-swiper-next{
                    /*display:none !important;*/
                }
            }
            .our-doctors-container{
                > .title{
                    color:$black !important;
                }
                > .subtitle{
                    color:$black !important;
                }
            }
            .footer-navigation{
                display:none !important;
            }
        }

        /* Small Devices, Tablets */
        @media only screen and (min-width : 768px) and (max-width: 991px) {
            #desktop-navigation{
                display:none;
            }
            #mobile-tablet-navigation{
                display:block;
                position:relative;
                z-index:2;
            }
            .service-swiper-container{
                .mobile-swiper{
                    display:none !important;
                }
                .desktop-swiper{
                    display:none !important;
                }
                .tablet-swiper{
                    display:block !important;
                }
            }
            .our-doctors-container{
                > .title{
                    color:$black !important;
                }
                > .subtitle{
                    color:$black !important;
                }
            }
        }

        /* Medium Devices, Desktops */
        @media only screen and (min-width : 992px) {
            #mobile-tablet-navigation{
                display: none;
            }

        }

        /* Large Devices, Wide Screens */
        @media only screen and (min-width : 1200px) {
            #mobile-tablet-navigation{
                display: none;
            }

        }
</style>
