<template lang="html">
    <div class="service-card-container">
        <b-container fluid>
            <b-container>
                <div class="d-flex flex-wrap mt-60" style="flex:1; flex-wrap:wrap;">
                    <div v-for="(service, idx) in serviceCards" :key="idx"
                         class="service-card-inner-container"
                         :class="idx === 0 ? 'mr-10' : 'ml-10'"
                    >
                        <div class="title">{{service.title}}</div>
                        <div class="description">{{service.description}}</div>
                        <div class="link">
                            <span v-if="service.link_type === 'hyperlink'">
                                <router-link :to="service.path">{{service.link}}</router-link>
                            </span>
                            <span v-else>
                                <a :href="`tel:${service.path}`">
                                {{service.link}}
                                </a>
                            </span>

                        </div>
                    </div>
                </div>
            </b-container>
        </b-container>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import serviceCardsEn from "../../store/data/home-service-cards-en.json";
    import serviceCardsHr from "../../store/data/home-service-cards-hr.json";
    import serviceCardsIt from "../../store/data/home-service-cards-it.json";

    export default {
        name    : "ServiceCardPreview",
        computed: {
            ...mapGetters(['getLanguage']),
            language() {
                return this.getLanguage;
            },
            serviceCards() {
                return this.getServiceCards();
            }
        },
        methods : {
            getServiceCards() {
                let cards;
                switch (this.language) {
                    case "en":
                        cards = serviceCardsEn;
                        break;
                    case "hr":
                        cards = serviceCardsHr;
                        break;
                    case "it":
                        cards = serviceCardsIt;
                        break;
                    default:
                        return serviceCardsEn;
                }
                return cards;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/custom.scss";
    @import '../../assets/styles/margins.css';

    .service-card-container {
        background: $gray;
        .service-card-inner-container {
            position: relative;
            background: $white;
            z-index: 1;
            border-radius: 10px;
            padding: 30px;
            box-shadow: 10px 20px 60px 0 rgba(0, 0, 0, 0.06);
            color: $black;
            flex: 0.5;
            .title {
                font-size: 25px;
                font-family: ProductSans-Black;
            }
            .description {
                margin: 20px 0;
                font-size: 18px;
                font-family: ProductSans-Light;
            }
            .link {
                color: $primary;
                font-size: 18px;
                font-family: ProductSans-Medium;
            }
        }
    }
</style>
