<template lang="html">
  <div class="about-container">
      <intro-section></intro-section>
      <exterier-interier></exterier-interier>
      <certificates></certificates>
  </div>
</template>

<script>
import IntroSection from "../components/About/IntroSection";
import ExterierInterier from "../components/About/ExterierInterier";
import Certificates from "../components/About/Certificates";
export default {
  name: 'About',
  components:{
    'intro-section': IntroSection,
    'exterier-interier': ExterierInterier,
    'certificates': Certificates
  },
  data() {
    return {
      title: 'About',
    }
  }
}
</script>

<style lang="scss" scoped >

</style>
